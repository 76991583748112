<template>
    <v-footer v-if="!isAuthenticated()" bottom app padless dense tile>
        <v-row justify="space-between">
            <v-col cols="8">
                {{ $t('footer.notloggedin') }} {{ getEnv() }}
            </v-col>
            <v-col v-if="getLocalEnvironment() !== ''" cols="1">
                <v-card :color="getEnvColor()" flat>
                    <v-card-text>{{ getLocalEnvironment() }}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-btn icon small @click="setLocale()">
                    <v-img
                        :alt="$t('alt.locale')"
                        :src="getIconSVG()"
                        height="15px"
                        width="20px"
                    />
                </v-btn>
            </v-col>
        </v-row>
    </v-footer>
    <v-footer
        v-else
        location="bottom"
        :app="true"
        variant="tile"
        density="compact"
        :hide-on-scroll="true"
        color="var(--color-brand-200)"
    >
        <!-- IS AUTHENTICATED -->
        <v-toolbar-items>
            <v-btn text> &copy; v{{ getEnv() }} </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
            <v-btn text>
                <span class="nametext">
                    {{ loggedInAs() }}
                    {{ schoolInfo() }}</span
                >
            </v-btn>
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items>
            <v-btn icon small aria-label="Set langauge" @click="setLocale()">
                <v-img
                    :alt="$t('alt.hl')"
                    :src="getIconSVG()"
                    height="15px"
                    width="20px"
                />
            </v-btn>
        </v-toolbar-items>
    </v-footer>
</template>

<script>
import adminStore from '@/stores/adminstore'
import { mapActions, mapState } from 'pinia'

import app from '../../../package.json'

export default {
    name: 'FooterMenu',
    // props: ['auth', 'authenticated'],
    data() {
        return {
            svgs: {
                nb: '/svgs/nb.svg',
                en: '/svgs/en.svg'
            }
        }
    },
    mounted() {},
    beforeMount() {
        console.log(this.$i18n.locale)
        let webLang = get3DigitLangCode(this.$i18n.locale)
        console.log('=== WEB LANG === (%o)', webLang)
        this.setLang(webLang)
        localStorage.setItem('lang', webLang)
        this.$date.locale(this.$root.$i18n.locale)
    },
    methods: {
        ...mapState(adminStore, [
            'getLoggedIn',
            'setLang',
            'getDisplayName',
            'getEnvironment',
            'getBGroups',
            'getOrgGroups'
        ]),
        getIconSVG() {
            let locale = this.$root.$i18n.locale
            console.log('getIconLocale (%s)', locale)
            if (locale === 'nb') {
                return this.svgs.nb
            } else if (locale === 'en') {
                return this.svgs.en
            }
        },
        setLocale() {
            let locale = this.$root.$i18n.locale
            let webLang = 'nob'
            if (locale === 'nb') {
                this.$root.$i18n.locale = 'en'
                webLang = get3DigitLangCode('en')
                this.$date.locale(this.$root.$i18n.locale)
            } else if (locale === 'en') {
                this.$root.$i18n.locale = 'nb'
                webLang = get3DigitLangCode('nb')
                this.$date.locale(this.$root.$i18n.locale)
            }
            console.log('=== WEB LANG === (%o)', webLang)
            this.setLang(webLang)
            localStorage.setItem('lang', webLang)
        },
        isAuthenticated() {
            let isLoggedIn = this.getLoggedIn()
            if (isLoggedIn !== undefined && isLoggedIn) {
                return true
            }
            return false
        },
        mainFooterColor() {
            return 'rgb(155,165,175)'
        },
        loggedInAs() {
            var displayName = this.getDisplayName()
            if (
                this.isAuthenticated() &&
                displayName !== undefined &&
                displayName !== ''
            ) {
                return displayName
            }
            return '[NOT LOGGED IN]'
        },
        getEnvColor() {
            let env = this.getEnvironment()
            if (env === 'stage') {
                return '#7cb342'
            } else if (env === 'dev') {
                return '#ffa000'
            }
            return 'rgb(155,165,175)'
        },
        getLocalEnvironment() {
            let env = this.getEnvironment()
            if (env === 'stage') {
                return 'S'
            } else if (env === 'dev') {
                return 'D'
            }
            return ''
        },
        isProduction() {
            let env = this.getEnvironment()
            if (env === 'stage' || env === 'dev') {
                return false
            }
            return true
        },
        schoolInfo() {
            let bGroups = this.getBGroups()
            let orgGroups = this.getOrgGroups()
            if (bGroups[0] !== undefined && orgGroups[1] !== undefined) {
                return (
                    ' - ' +
                    bGroups[0].displayName +
                    ' - ' +
                    orgGroups[1].displayName
                )
            } else {
                return ''
            }
        },
        getEnv() {
            return app.version
        },
        getDomainServer() {
            return localStorage.getItem('domainServer')
        },
        setLanguage(lang) {
            console.log('SetLanguage: ' + lang)
            this.$i18n.locale = lang
            this.$moment.locale(lang)
            localStorage.setItem('hyplang', lang)
            let webLang = get3DigitLangCode(this.$i18n.locale)
            console.log('=== WEB LANG === (%o)', webLang)
            this.setLang(webLang)
            localStorage.setItem('lang', webLang)
        },
        getColor(lang) {
            const selectedLang = localStorage.getItem('hyplang')
            if (lang === selectedLang) {
                return 'blue darken-2'
            }
            return ''
        },
        getFooterColor() {
            return 'rgb(217,222,226)'
            // return 'rgb(100,222,226)'
        },
        getServerClient() {
            return localStorage.getItem('hypsaserver')
        },
        getHostname() {
            return location.hostname
        }
    }
}

function get3DigitLangCode(lang2) {
    switch (lang2.toLowerCase()) {
        case 'nb':
            return 'nob'
        case 'nn':
            return 'nno'
        case 'en':
            return 'eng'
        case 'no':
            return 'nor'
        case 'se':
            return 'sme'
        default:
            return 'nob'
    }
}
</script>

<style lang="css">
.footer {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    color: rgb(100, 100, 100);
}

.footerinfo {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(100, 100, 100);
}

.languagechooser {
    text-align: left;
    font-size: calc(0.6em + 1vmin);
    margin-left: 20px;
    color: rgb(100, 100, 100);
}

.hostinfo {
    padding-left: 20px;
    text-align: left;
    font-size: calc(0.6em + 0.7vmin);
    color: rgb(100, 100, 100);
}

.domainserver {
    padding-left: 10px;
    font-size: calc(0.3em + 0.3vmin);
    color: rgb(100, 100, 100);
}

.nametext {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: capitalize;
}
</style>
