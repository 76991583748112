<template>
    <v-app id="app">
        <v-main v-if="!getLoggedIn()" class="content">
            <router-view />
            <NotifyUser
                v-if="hasNotification"
                @notificationseen="notificationDone"
            />
        </v-main>
        <template v-else>
            <NavigationSystem class="navigationtop" location="top" />
            <v-main class="content">
                <router-view />
            </v-main>
            <FooterMenu />
        </template>
        <BeatLoader
            class="spinnerloader"
            :loading="getLogInProcess()"
            color="var(--color-brand)"
            size="10rem"
        />
    </v-app>
</template>

<script>
import { adminStore } from '@/stores/adminstore.js'
import { mapActions, mapState } from 'pinia'
import { useToast } from 'vue-toastification'
// import { inject } from "vue";

// import offline from "@/injects/offline.js";
// import update from "@/injects/update.js";

import FooterMenu from '@/components/menues/FooterMenu.vue'
import NavigationSystem from '@/components/menues/NavigationSystem.vue'
import NotifyUser from '@/components/notify/NotifyUser.vue'
import { BeatLoader } from 'vue3-spinner'

import app from '@/../package.json'

export default {
    name: 'App',
    components: {
        FooterMenu,
        NavigationSystem,
        BeatLoader,
        NotifyUser
    },
    //  inject: ["update", "offline"],
    beforeRouteEnter(to, from, next) {
        console.error('------- APP VUE BEFORE ENTER -------- ')
        console.log('to: (%o) from: (%o) next: (%o)', to, from, next)
    },
    data() {
        return {
            toast: useToast(),
            incrementSeconds: 0,
            hasNotification: true
        }
    },
    beforeMount() {
        console.info(
            '%c(c) Hypatia Learning AS\n%cDeveloped in collaboration with \nØstfold University College and \nVolda University College\n%cVersion: %s',
            'background: rgba(12, 77, 161, 0.8); color: hsl(0, 0%, 100%); font-size:1.3rem; font-weight:800;',
            'background:  rgba(12, 77, 161, 0.8); color: hsl(0, 0%, 100%);',
            'background:  rgba(12, 77, 161, 0.2); color: rgba(0,0,155,0.8);',
            app.version
        )
        console.log('APP BEFORE MOUNT <<<<<<<<<<<<<')
        let serverUrlInfo = localStorage.getItem('hypsaserver') || ''
        let registryUrlInfo = localStorage.getItem('hypregistry') || ''
        console.log(
            '==== A P P  serverUrl: (' +
                serverUrlInfo +
                ') registryUrl(' +
                registryUrlInfo +
                ')'
        )
        if (registryUrlInfo === '' || serverUrlInfo === '') {
            console.info('*** MISSING SOME ESENNTIAL REGISTRY INFORMATION ***')
            // @todo fix set to nothing...
            // localStorage.setItem('hypregistry', 'https://www.hypatia.no')
            if (location.hostname === 'laerer.v4l.no') {
                this.addRegistryUrl('https://www.ataa.no/')
            } else if (location.hostname === 'stage-laerer.v4l.no') {
                this.addRegistryUrl('https://www.ataa.no/stage/')
            } else if (location.hostname === 'stage-laerer.proclus.no') {
                this.addRegistryUrl('https://www.ataa.no/stage/')
            } else if (location.hostname === 'laerer.proclus.no') {
                this.addRegistryUrl('https://www.ataa.no/')
            } else if (location.hostname === 'v4leducator.localhost') {
                this.addRegistryUrl('http://localhost/')
            }
        } else {
            this.setServerUrl(serverUrlInfo)
            this.setRegistryUrl(registryUrlInfo)
            this.getPingServer()
                .then((result) => {
                    console.log(
                        'RESULT FOR APP - We have working connection with server !! '
                    )
                    console.log(result)
                })
                .catch((error) => {
                    console.info(
                        '*** ERROR *** could not get contact with PING server! ' +
                            error
                    )
                    if (location.hostname === 'laerer.v4l.no') {
                        this.addRegistryUrl('https://www.ataa.no/')
                    } else if (location.hostname === 'stage-laerer.v4l.no') {
                        this.addRegistryUrl('https://www.ataa.no/stage/')
                    } else if (
                        location.hostname === 'stage-laerer.proclus.no'
                    ) {
                        this.addRegistryUrl('https://www.ataa.no/stage/')
                    } else if (location.hostname === 'laerer.proclus.no') {
                        this.addRegistryUrl('https://www.ataa.no/')
                    } else if (location.hostname === 'v4leducator.localhost') {
                        this.addRegistryUrl('http://localhost/')
                    }
                    this.$router.push({ name: 'MustLogin' }).catch((e) => {
                        console.error('COULD NOT ROUTE to mustlogin (%j)', e)
                    })
                })
        }
        if (
            location.hostname === 'laerer.v4l.no' ||
            location.hostname === 'laerer.proclus.no'
        ) {
            this.setEnvironment('prod')
        } else if (
            location.hostname === 'stage-laerer.v4l.no' ||
            location.hostname === 'stage-laerer.proclus.no'
        ) {
            this.setEnvironment('stage')
        } else if (location.hostname === 'v4leducator.localhost') {
            this.setEnvironment('dev')
        }
    },
    mounted() {
        console.log('++++++++++ APP is mounted ++++++++')

        addEventListener('offline', (event) => {
            // Update your UI to reflect that there's no connection.
            console.warn('*** WARN *** we are OFFLINE ! (%j)', event)
            /*  this.toast.error(this.$t('gui.offlineheading') + ' ' + this.$t('gui.offlinetext'), {
        timeout: false
      })
      */
        })
        addEventListener('online', (event) => {
            // Update your UI to reflect that there's no connection.
            console.warn('*** WARN *** we are ONLINE ! (%j)', event)
            //   this.toast.success(this.$t('gui.onlineheading') + ' ' + this.$t('gui.onlinetext'))
        })
        window.addEventListener('updateExists', () => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we have UPDATE !')
            this.toast.info(this.$t('gui.updateheading'))
        })
        window.addEventListener('online', (event) => {
            // Update your UI to reflect that the connection is back.
            console.warn('*** WARN *** we are back ONLINE (%j)!', event)
            this.toast.info(
                this.$t('gui.onlineheading') + ' ' + this.$t('gui.onlinetext')
            )
        })

        navigator.connection.addEventListener('change', () => {
            console.info('CONNECTION CHANGED TO (%j)', navigator.connection)
        })
        window.onoffline = (event) => {
            console.log('ONOFFLINE (%j)', event)
            if (event.type === 'offline') {
                this.toast.error(
                    this.$t('gui.offlineheading') +
                        ' ' +
                        this.$t('gui.offlinetext'),
                    {
                        timeout: false
                    }
                )
            }
        }
        window.ononline = (event) => {
            console.log('ononline (%j)', event)
            if (event.type === 'online') {
                this.toast.success(
                    this.$t('gui.onlineheading') +
                        ' ' +
                        this.$t('gui.onlinetext')
                )
            }
        }
        /*
        let datePayload = {
            toDate: this.$date().add(1, 'day').format('YYYY-MM-DD'),
            fromDate: this.$date()
                .subtract(2, 'month')
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
        }
        // this.setSearchDate(datePayload); // SHOULD BE LOADED FROM STORES
        */
    },
    beforeDestroy() {
        console.log('APP BEFORE DESTROY <<<<<<<<<<<<<')
        removeEventListener('offline')
        removeEventListener('online')
        removeEventListener('updateExists')
        removeEventListener('online')
        removeEventListener('connection')
        removeEventListener('onoffline')
        removeEventListener('ononline')
    },
    methods: {
        ...mapActions(adminStore, [
            'addRegistryUrl',
            'setServerUrl',
            'setRegistryUrl',
            'getPingServer'
        ]),
        ...mapState(adminStore, [
            'setEnvironment',
            'getLoggedIn',
            'getLogInProcess'
        ]),
        refreshApp() {
            location.reload(true)
        },
        notificationDone() {
            console.log('GOT Notification DONE ')
            this.hasNotification = false
        }
    }
}
</script>

<style lang="scss" scoped>
.spinnerloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>
