console.log('*** START *** BASE 64')

function isBase64Encoded(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

function base64encode(str) {
  if (typeof str !== 'string') {
    if (typeof str === 'number') {
      str = str.toString()
    } else {
      throw new Error('Text to encode must be a string or a number.')
    }
  }
  return Buffer.from(str, 'utf8').toString('base64')
}

function base64decode(str) {
  if (typeof str !== 'string') {
    throw new Error('Input value must be a string.')
  }
  return Buffer.from(str, 'base64').toString('utf8')
}

// var hypCrypt = new HypCrypt(null)
export function decodeLoad(load) {
  // console.log('DECODE ORG got called')
  /// console.log(load)
  // could also be extended to do checking of load
  // could also be extended to do decrypting of the load
  // console.log('====== LOAD =======')
  // console.log(load)
  if (load === undefined || load === null || load.length === 0) {
    console.warn('==== W A R N ==== load is (%s)', load)
    return ''
  }
  // return base64-decode(load)

  if (isBase64Encoded(load)) {
    return base64decode(load)
  } else {
    return load
  }
}

export function encodeLoad(load) {
  // could also be used to verify and check more data being transmitted.
  // could also be enhanced to encrypt the load if necessary.
  // return base64.encode(load)
  if (load === '') {
    return load
  }
  if (isBase64Encoded(load)) {
    return load
  }
  if (isBase64(load)) {
    return load
  }
  // console.log('ENCODE ORG got called')
  if (!isBase64Encoded(load)) {
    return base64encode(load)
  } else {
    return load
  } // return encodeURI(load)
  // return load
}

const encodeBuffer = (buffer) => buffer.toString('base64')
const encodeString = (string) => encodeBuffer(Buffer.from(string))
const encodeData = (data) => encodeString(JSON.stringify(data))

export const encode = (data) => {
  if (Buffer.isBuffer(data)) return encodeBuffer(data)
  if (typeof data === 'string') return encodeString(data)
  return encodeData(data)
}

export const decode = (string) => {
  const decoded = Buffer.from(string, 'base64').toString()
  try {
    return JSON.parse(decoded)
  } catch (e) {
    return decoded
  }
}

function isBase64(str) {
  try {
    return btoa(atob(str)) == str
  } catch (err) {
    return false
  }
}

console.log('*** DONE *** BASE 64')

// module.exports = { decodeLoad, encodeLoad, encode, decode }
