<template>
    <v-dialog
        transition="dialog-bottom-transition"
        v-model="showNotification"
        width="80vw"
        height="30vh"
    >
        <v-card
            :title="notifications.title"
            :text="notifications.message"
            :color="getColor(notifications)"
            append-icon="mdi-close"
            @click="closeNotification"
        />
    </v-dialog>
</template>

<script>
import { adminStore } from '@/stores/adminstore.js'
import { mapGetters, mapActions } from 'pinia'

export default {
    name: 'NotifyUser',
    props: {},
    data() {
        return {
            notifications: {},
            showNotification: false
        }
    },
    beforeMount() {
        console.log('NotifyUser Before MOUNT  got called')
        this.getNotifications()
    },
    mounted() {
        console.log('NotifyUser mounted got called')
    },
    methods: {
        ...mapGetters(adminStore, ['getServerUrl']),
        ...mapActions(adminStore, ['getHTTP']),
        getNotifications() {
            console.log('getNotifications got called')
            this.getHTTP('notificationeducator')
                .then((response) => {
                    console.log(
                        'NotifyUser getNotifications returned (%J)',
                        response
                    )
                    this.notifications = response.data
                    let notificationId =
                        localStorage.getItem('notificationid') || ''
                    if (this.notifications.id === notificationId) {
                        this.$emit('notificationseen')
                    } else {
                        this.showNotification = true
                        localStorage.setItem(
                            'notificationid',
                            this.notifications.id
                        )
                    }
                })
                .catch((error) => {
                    console.log('NotifyUser getNotifications error (%J)', error)
                })
        },
        closeNotification() {
            this.showNotification = false
            localStorage.setItem('notificationid', this.notifications.id)
        },
        getColor(notification) {
            if (notification.type === 'info') {
                return 'var(--color-notification-info)'
            } else if (notification.type === 'warning') {
                return 'var(--color-notification-warning)'
            } else if (notification.type === 'error') {
                return 'var(--color-notification-error)'
            } else if (notification.type === 'update') {
                return 'var(--color-notification-update)'
            } else {
                return 'var(--color-notification-update)'
            }
        }
    }
}
</script>

<style scoped></style>
