/* SA EDUCATOR */

import { createRouter, createWebHistory } from 'vue-router'

import LandingPage from '@/views/LandingPage.vue'
import adminStore from '@/stores/adminstore.js'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/landingpage',
      name: 'LandingPage',
      component: LandingPage
    },
    {
      path: '/mustlogin',
      name: 'MustLogin',
      component: () => import('@/views/MustLogin.vue')
    },
    {
      path: '/logout',
      name: 'LogOut',
      component: () => import('@/views/LogOut.vue')
    },
    {
      path: '/callback',
      name: 'Callback',
      props: (route) => ({
        query: route.query.code
      }),
      component: () => import('@/views/LoginCallback.vue')
    },
    {
      path: '/loggedin',
      name: 'LoggedIn',
      component: () => import('@/views/LoggedIn.vue')
    },
    {
      path: '/noschool',
      name: 'NoSchoolRegistered',
      component: () => import('@/views/NoSchoolRegistered.vue')
    },
    {
      path: '/competencyassessment',
      name: 'CompetencyAssessmentSummary',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CompetencyAssessmentSummary.vue')
    },
    {
      path: '/competencyassessmentdoughnut',
      name: 'CompetencyAssessmentSummaryDoughnut',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CompetencyAssessmentSummaryDoughnut.vue')
    },
    {
      path: '/error/:errHeading/:errMessage',
      name: 'Error',
      component: () => import('@/views/Error.vue'),
      props: true
    },
    {
      path: '/errornoeducator',
      name: 'ErrorNoEducator',
      component: () => import('@/views/ErrorNoEducator.vue')
    },
    {
      path: '/settings',
      name: 'EducatorSettings',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorSettings.vue')
    },
    {
      path: '/learnerview',
      name: 'LearnerViewInput',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/LearnerViewInput.vue')
    },
    {
      path: '/',
      name: 'V4LStartPage',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/V4LStartPage.vue')
    },
    {
      path: '/subjects',
      name: 'Subjects',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/SchoolSubjects.vue')
    },
    {
      path: '/adaptations',
      name: 'EducatorAdaptations',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorAdaptations.vue')
    },
    {
      path: '/check',
      name: 'CheckSessionLogin',
      component: () => import('@/views/CheckSessionLogin.vue')
    },
    /* V4L */
    {
      path: '/assessassignments',
      name: 'EducatorAssessAssignments', // tile 1
      beforeEnter: requireAuthentication,
      component: () => import('@/views/EducatorAssessAssignments.vue')
    },
    {
      path: '/resultpeerreview',
      name: 'ResultFromPeerReviewOfAssignments', // tile 2
      beforeEnter: requireAuthentication,
      component: () => import('@/views/ResultFromPeerReviewOfAssignments.vue')
    },
    {
      path: '/inprocess',
      name: 'TodaysActiveAssignments', // tile 3
      beforeEnter: requireAuthentication,
      component: () => import('@/views/TodaysActiveAssignments.vue')
    },
    {
      path: '/createassignment',
      name: 'CreateAssignment',
      beforeEnter: requireAuthentication,
      component: () => import('@/views/CreateAssignment.vue')
    },
    {
      path: '/assesswrittentext',
      name: 'AssessText',
      beforeEnter: requireAuthentication,
      component: () => import('@/components/assessment/AssessText.vue')
    },
    {
      path: '/curriculum',
      name: 'CurriculumView',
      beforeEnter: requireAuthentication,
      component: () => import('@/components/curriculum/CurriculumView.vue')
    } /*,

    {
      path: '*',
      name: 'ErrorPage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import('@/views/ErrorPage.vue')
    }
    */
  ]
})

function requireAuthentication(to, from, next) {
  let aStore = adminStore()
  console.log(
    '========= REQUIRE AUTHENTICATION ======== to: ' +
      to.name +
      ' - ' +
      to.fullPath +
      ' FROM:' +
      from.name
  )
  //  console.log('STORE TEST:')
  //  console.log(store.getters.getVersion)

  if (checkSSO()) {
    console.log('---------- REDIRECTING TO CHECK SESSION LOGIN --------')
    next({
      //      path: '/mustlogin'
      name: 'CheckSessionLogin'
    })
  } else if (!aStore.loggedin) {
    console.log('---------- REDIRECTING TO MUST LOGIN --------')
    aStore.$reset()
    aStore.logout()
    next({
      path: '/mustlogin'
    })
  } else {
    // let nowSeconds = Math.floor(Date.now() / 1000)
    // store.commit('setAccessTokenExpires', nowSeconds + 3600 * 24) // add 24 hour to expires
    console.log('>>>>>> We are authenticated >>>>>>')
    next()
  }
}

function checkSSO() {
  let aStore = adminStore()
  let ssoData = localStorage.getItem('lid') || ''
  console.log('SSO DATA: (%s)', aStore.loggedin)
  // console.log(ssoData)
  if (ssoData !== '' && !aStore.loggedin) {
    console.log('** WE HAVE SSO DATA ** ')
    return true
  }
  return false
}

export default router
